import NavBar from "../components/navbar.component";
import Article1 from "../articles/1.introduction.component";
import "../style/article.css"


function App() {
  return (
    <div className="App">
      <NavBar />
      <Article1/>

    </div>
  );
}

export default App;
