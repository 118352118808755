import React  from "react";
import "../style/footer.css";


export default function Footer() {
    return(
        <>
            <div class="card-body white footer">
                <ul className="footer-tab">
                    {/* <li><a href="https://twitter.com/julesforrest">Twitter</a></li> */}
                    {/* <li><a href="https://codepen.io/julesforrest">Codepen</a></li> */}
                    {/* <li><a href="mailto:julesforrest@gmail.com">Email</a></li>
                    <li><a href="https://dribbble.com/julesforrest">Dribbble</a></li> */}
                    <li className="footer-li"><a className="footer-link" href="https://www.freeprivacypolicy.com/live/78b80d45-f6ad-4e3f-866f-4b51fc347824">Term and conditions</a></li>
                    <li className="footer-li"><a className="footer-link" href="https://www.freeprivacypolicy.com/live/3e200140-6d1c-477f-94ea-8f7154262812">Privacy Policy</a></li>
                    <li className="footer-li"><a className="footer-link" href="https://consent.cookiebot.com/72e6974c-8e10-465c-ba2d-d31a3b695af1/cd.js">cookie declaration</a></li>
                    <li className="footer-li">
                    <p>👋</p>
                    </li>
                </ul>


            </div>
        </>
    );
}